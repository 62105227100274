<template>
  <div class="page">
    <div v-if="is_weixin" class="top_right">请在浏览器中打开</div>
    <div class="app_div">
      <img src="https://www.luckysee.cn/static/img/logo.png">
      <span class="title">乐见虫草</span>
      <span class="desc">用AI视觉帮你找虫草，没网也能用</span>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Utils from '../../utils/utils'
export default {
  name: 'downloads',
  data () {
    return {
      is_weixin: Utils.is_weixin(),
      is_mobile: Utils.is_mobile(),
      is_ios: Utils.is_ios()
    }
  },
  mounted () {
    this.get_index()
    if (this.is_mobile && !this.is_weixin) {
      if (this.is_ios) {
        // console.log(1)
        location.href = 'https://apps.apple.com/cn/app/1615388462'
      } else {
        // console.log(2)
        location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=cn.luckysee.app'
      }
    }
  },
  methods: {
    get_index () {
      axios.post('/log_sth_unlogin/', { tag: 'downloads_app', qid: this.$route.query.qid })
        .then(res => {
          // console.log(res)
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          console.log(222)
        })
    }
  }
}
</script>

<style scoped>
/* html,
body,
#app,.page{
  width: 100%;
  height: 100%;
} */
.page{
  background: url('https://www.luckysee.cn/static/img/app_share_background.png') center no-repeat;
  background-size: cover;
  position: fixed;
  width: 100%;
  height: 100%;
}
.top_right{
    width: 184px;
    height: 67px;
    background: url('https://www.luckysee.cn/static/img/app_share_topright.png') center no-repeat;
    background-size: 184px auto;
    font-size: 18px;
    color: #FFFFFF;
    text-align: center;
    line-height: 83px;
    position: absolute;
    right: 25px;
    top: 0;
}
.app_div{
  text-align: center;
  position: absolute;
  top: 45%;
  width: 100%;
  transform: translateY(-50%);
}
.app_div img{
  border-radius: 36px;

}
.app_div img,.app_div span{
  display: block;
  margin: 10px auto;
}
.app_div .title{
  font-size: 32px;
  font-weight: 800;
  color: #000;
}
.app_div .desc{
  font-size: 20px;
  font-weight: 400;
  color: #000;

}
</style>
